<script setup lang="ts">
const siteTitle = 'iCheckup!'

// https://nuxt.com/docs/getting-started/seo-meta#title-template
useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${siteTitle} - ${titleChunk}` : siteTitle
  },
})

const { isNotProduction } = useRuntimeConfig().public
const environmentHeaderHeight = isNotProduction ? '24px' : '0px'

const { isActive } = useLoading()
</script>

<template>
  <div>
    <article v-if="isNotProduction">
      <div class="message-header environment-block">NOT PRODUCTION / SAFE TO USE</div>
    </article>
    <LoadingModal :is-active="isActive" />
    <TheSnackbar />
    <div id="page" class="layout-block">
      <NuxtLayout>
        <NuxtPage />
      </NuxtLayout>
    </div>
    <div id="preview" />
    <div id="dialog" />
  </div>
</template>

<style lang="scss" scoped>
$header-height: v-bind(environmentHeaderHeight);
.environment-block {
  height: $header-height;
  background-color: hsl(204, 86%, 53%);
  font-size: 0.6rem;
}
.layout-block {
  height: calc(100vh - $header-height);
}

#preview {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
}

#dialog {
  position: absolute;
  z-index: 1;
}

#page {
  position: relative;
  z-index: 0;
}
</style>
