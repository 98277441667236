
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexTnKDCJ532zeLgEfsbYqwalT6wHtFqIqkE05JzDTIc8UMeta } from "/home/runner/work/checkup/checkup/apps/operator/src/pages/index.vue?macro=true";
import { default as loginr38d0qxPbgEtwnd9unq6x442OspcAPailrxmNS5TMAMMeta } from "/home/runner/work/checkup/checkup/apps/operator/src/pages/login.vue?macro=true";
import { default as indexpK5CSCol23qlaV46Y67_Yst4_NpsK0B7rXk09Du0HiAMeta } from "/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/tickets/index.vue?macro=true";
import { default as selecttxc4hN76g1zVaUxnMrAoPVRd7v21_s7GZree_PB9hbkMeta } from "/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/operators/select.vue?macro=true";
import { default as indexiMhxa3M5c6acYVuVdGypdt2jWe5m3ZKeAciOgG8FjbEMeta } from "/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/select/index.vue?macro=true";
import { default as index7M4H7MC5wL64Sv0lAsUPlKFSn2_Viim0ZP28yCGT4RUMeta } from "/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/tickets/[ticketId]/index.vue?macro=true";
import { default as _91checkFileId_93jil_45weBpDRaC_45el5XmlvwDwqyC3z5u7w0sel0R5mO9kMeta } from "/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/select/[checkFileId].vue?macro=true";
import { default as indexUaAX60Cs9GAbJKd7r7dgbsuJAkZQxgZLCtyOhy9HK1MMeta } from "/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/tickets/index.vue?macro=true";
import { default as indexcp8Qqv23T7ztodXs_1H4mK5Qdw05TfgRsLcp3cdBYdMMeta } from "/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/[yyyymmdd]/index.vue?macro=true";
import { default as index3Co7ruqLkF7jJd5D_45q4l_45pfeGALvdxMEzQGTqSbhWLMMeta } from "/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/[yyyymmdd]/todo/index.vue?macro=true";
import { default as indexMTDtl9M_KN266tRXnUkmTMh_pwNoHwLD_shIZO75gTYMeta } from "/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/tickets/[ticketId]/index.vue?macro=true";
import { default as index2kS_45urPdNob5lTeX2zZ0sn3BqRSMSqOHui5_45QpvHwZEMeta } from "/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/workFiles/[yyyy]/[mm]/index.vue?macro=true";
import { default as indexWz2WMVtfb_odd5iwe6R9rEYN56xRL8dQFr_wcNdIsVsMeta } from "/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/workFiles/[yyyymmdd]/preview/index.vue?macro=true";
import { default as indexrpMtUmBt8vkE3IoSGCTf8SUMI8MUkm_G4UKiQoe3L7IMeta } from "/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/workFiles/[yyyymmdd]/timeboxes/index.vue?macro=true";
import { default as indexpwrD__VdcIhYU_v756tBOwQqoxnWSUSiJOAtctzDrgcMeta } from "/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/[yyyymmdd]/timeboxes/[timeboxId]/index.vue?macro=true";
import { default as index1BVpqyBt4qaN9EZWIeF29LeEzFOJBp_45o8f6shepWBpoMeta } from "/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/workFiles/[yyyymmdd]/timeboxes/[timeboxId]/index.vue?macro=true";
import { default as indexbZmnj3n5qZDRCkKzujlXxWadWZNxvMn4pQbKTTsKhHsMeta } from "/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/[yyyymmdd]/timeboxes/[timeboxId]/workSheets/[workSheetId]/index.vue?macro=true";
import { default as indexJlZUeMBP60Kq2caD1vSFkl_457DGOxrkQc09i_45_20uoOkMeta } from "/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/workFiles/[yyyymmdd]/timeboxes/[timeboxId]/workSheets/[workSheetId]/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/checkup/checkup/apps/operator/src/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/home/runner/work/checkup/checkup/apps/operator/src/pages/login.vue")
  },
  {
    name: "organizationId-tickets",
    path: "/:organizationId()/tickets",
    meta: indexpK5CSCol23qlaV46Y67_Yst4_NpsK0B7rXk09Du0HiAMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/tickets/index.vue")
  },
  {
    name: "organizationId-operators-select",
    path: "/:organizationId()/operators/select",
    meta: selecttxc4hN76g1zVaUxnMrAoPVRd7v21_s7GZree_PB9hbkMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/operators/select.vue")
  },
  {
    name: "organizationId-checkFiles-select",
    path: "/:organizationId()/checkFiles/select",
    meta: indexiMhxa3M5c6acYVuVdGypdt2jWe5m3ZKeAciOgG8FjbEMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/select/index.vue")
  },
  {
    name: "organizationId-tickets-ticketId",
    path: "/:organizationId()/tickets/:ticketId()",
    meta: index7M4H7MC5wL64Sv0lAsUPlKFSn2_Viim0ZP28yCGT4RUMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/tickets/[ticketId]/index.vue")
  },
  {
    name: "organizationId-checkFiles-select-checkFileId",
    path: "/:organizationId()/checkFiles/select/:checkFileId()",
    component: () => import("/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/select/[checkFileId].vue")
  },
  {
    name: "organizationId-checkFiles-checkFileId-tickets",
    path: "/:organizationId()/checkFiles/:checkFileId()/tickets",
    meta: indexUaAX60Cs9GAbJKd7r7dgbsuJAkZQxgZLCtyOhy9HK1MMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/tickets/index.vue")
  },
  {
    name: "organizationId-checkFiles-checkFileId-yyyymmdd",
    path: "/:organizationId()/checkFiles/:checkFileId()/:yyyymmdd()",
    meta: indexcp8Qqv23T7ztodXs_1H4mK5Qdw05TfgRsLcp3cdBYdMMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/[yyyymmdd]/index.vue")
  },
  {
    name: "organizationId-checkFiles-checkFileId-yyyymmdd-todo",
    path: "/:organizationId()/checkFiles/:checkFileId()/:yyyymmdd()/todo",
    meta: index3Co7ruqLkF7jJd5D_45q4l_45pfeGALvdxMEzQGTqSbhWLMMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/[yyyymmdd]/todo/index.vue")
  },
  {
    name: "organizationId-checkFiles-checkFileId-tickets-ticketId",
    path: "/:organizationId()/checkFiles/:checkFileId()/tickets/:ticketId()",
    meta: indexMTDtl9M_KN266tRXnUkmTMh_pwNoHwLD_shIZO75gTYMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/tickets/[ticketId]/index.vue")
  },
  {
    name: "organizationId-checkFiles-checkFileId-workFiles-yyyy-mm",
    path: "/:organizationId()/checkFiles/:checkFileId()/workFiles/:yyyy()/:mm()",
    meta: index2kS_45urPdNob5lTeX2zZ0sn3BqRSMSqOHui5_45QpvHwZEMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/workFiles/[yyyy]/[mm]/index.vue")
  },
  {
    name: "organizationId-checkFiles-checkFileId-workFiles-yyyymmdd-preview",
    path: "/:organizationId()/checkFiles/:checkFileId()/workFiles/:yyyymmdd()/preview",
    meta: indexWz2WMVtfb_odd5iwe6R9rEYN56xRL8dQFr_wcNdIsVsMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/workFiles/[yyyymmdd]/preview/index.vue")
  },
  {
    name: "organizationId-checkFiles-checkFileId-workFiles-yyyymmdd-timeboxes",
    path: "/:organizationId()/checkFiles/:checkFileId()/workFiles/:yyyymmdd()/timeboxes",
    meta: indexrpMtUmBt8vkE3IoSGCTf8SUMI8MUkm_G4UKiQoe3L7IMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/workFiles/[yyyymmdd]/timeboxes/index.vue")
  },
  {
    name: "organizationId-checkFiles-checkFileId-yyyymmdd-timeboxes-timeboxId",
    path: "/:organizationId()/checkFiles/:checkFileId()/:yyyymmdd()/timeboxes/:timeboxId()",
    meta: indexpwrD__VdcIhYU_v756tBOwQqoxnWSUSiJOAtctzDrgcMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/[yyyymmdd]/timeboxes/[timeboxId]/index.vue")
  },
  {
    name: "organizationId-checkFiles-checkFileId-workFiles-yyyymmdd-timeboxes-timeboxId",
    path: "/:organizationId()/checkFiles/:checkFileId()/workFiles/:yyyymmdd()/timeboxes/:timeboxId()",
    meta: index1BVpqyBt4qaN9EZWIeF29LeEzFOJBp_45o8f6shepWBpoMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/workFiles/[yyyymmdd]/timeboxes/[timeboxId]/index.vue")
  },
  {
    name: "organizationId-checkFiles-checkFileId-yyyymmdd-timeboxes-timeboxId-workSheets-workSheetId",
    path: "/:organizationId()/checkFiles/:checkFileId()/:yyyymmdd()/timeboxes/:timeboxId()/workSheets/:workSheetId()",
    meta: indexbZmnj3n5qZDRCkKzujlXxWadWZNxvMn4pQbKTTsKhHsMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/[yyyymmdd]/timeboxes/[timeboxId]/workSheets/[workSheetId]/index.vue")
  },
  {
    name: "organizationId-checkFiles-checkFileId-workFiles-yyyymmdd-timeboxes-timeboxId-workSheets-workSheetId",
    path: "/:organizationId()/checkFiles/:checkFileId()/workFiles/:yyyymmdd()/timeboxes/:timeboxId()/workSheets/:workSheetId()",
    meta: indexJlZUeMBP60Kq2caD1vSFkl_457DGOxrkQc09i_45_20uoOkMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/workFiles/[yyyymmdd]/timeboxes/[timeboxId]/workSheets/[workSheetId]/index.vue")
  }
]