import revive_payload_client_rU3VE6Tq73eWkditiQQ__6W2ZeDboSUv9JI8Wt3b2pA from "/home/runner/work/checkup/checkup/apps/operator/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.17.28_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0__jxhmdlmb3recfkztir7pkurm4q/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_mAMZPDScU4MpERMwRAU5aPVoRO2pqcMKpMCE9UV_Lqs from "/home/runner/work/checkup/checkup/apps/operator/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.17.28_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0__jxhmdlmb3recfkztir7pkurm4q/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_zcYqiBWSEnGMjy2seTux7nenBzOVzj7hhaZkgcYc6LA from "/home/runner/work/checkup/checkup/apps/operator/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.17.28_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0__jxhmdlmb3recfkztir7pkurm4q/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_iYO3u1_PpP4Brf0ZWGVUm7UIj_lVvELYZAbOsrNKURY from "/home/runner/work/checkup/checkup/apps/operator/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.17.28_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0__jxhmdlmb3recfkztir7pkurm4q/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_S_u6hLvO2h5VoK3IwrbrPa9RHmX9cYxrJ6Fc4MzWR2w from "/home/runner/work/checkup/checkup/apps/operator/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.17.28_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0__jxhmdlmb3recfkztir7pkurm4q/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_91PbMww_BWLVhFwXZfIM19lYwCKVhVDk2YhaRlVOiMQ from "/home/runner/work/checkup/checkup/apps/operator/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.17.28_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0__jxhmdlmb3recfkztir7pkurm4q/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_j1aCLCS8HA_XkLy1_H_iKP9FaXbaPvbjQcjn6tc1AZ8 from "/home/runner/work/checkup/checkup/apps/operator/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.17.28_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0__jxhmdlmb3recfkztir7pkurm4q/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/home/runner/work/checkup/checkup/apps/operator/.nuxt/components.plugin.mjs";
import prefetch_client_7g_YtJlqj66kQywA6FLRizh0lsdccUm0fpXL2Fw5z3g from "/home/runner/work/checkup/checkup/apps/operator/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.17.28_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0__jxhmdlmb3recfkztir7pkurm4q/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import supabase_client_NEJ48DWoeI8lDASvKn782WCwfPalC5RbeheBQ67ptr4 from "/home/runner/work/checkup/checkup/apps/operator/src/plugins/supabase.client.ts";
import sentry_client_alKR4qwfOmNOPbQ_WTISchX5XdIUEtwpyIuPf735J_4 from "/home/runner/work/checkup/checkup/apps/operator/src/plugins/sentry.client.ts";
import i18n_OkFQ9LTcb1ALD8at3v1fSiJbXuv91qvs85z9kOsJ3og from "/home/runner/work/checkup/checkup/apps/operator/src/plugins/i18n.ts";
export default [
  revive_payload_client_rU3VE6Tq73eWkditiQQ__6W2ZeDboSUv9JI8Wt3b2pA,
  unhead_mAMZPDScU4MpERMwRAU5aPVoRO2pqcMKpMCE9UV_Lqs,
  router_zcYqiBWSEnGMjy2seTux7nenBzOVzj7hhaZkgcYc6LA,
  payload_client_iYO3u1_PpP4Brf0ZWGVUm7UIj_lVvELYZAbOsrNKURY,
  navigation_repaint_client_S_u6hLvO2h5VoK3IwrbrPa9RHmX9cYxrJ6Fc4MzWR2w,
  check_outdated_build_client_91PbMww_BWLVhFwXZfIM19lYwCKVhVDk2YhaRlVOiMQ,
  chunk_reload_client_j1aCLCS8HA_XkLy1_H_iKP9FaXbaPvbjQcjn6tc1AZ8,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_7g_YtJlqj66kQywA6FLRizh0lsdccUm0fpXL2Fw5z3g,
  supabase_client_NEJ48DWoeI8lDASvKn782WCwfPalC5RbeheBQ67ptr4,
  sentry_client_alKR4qwfOmNOPbQ_WTISchX5XdIUEtwpyIuPf735J_4,
  i18n_OkFQ9LTcb1ALD8at3v1fSiJbXuv91qvs85z9kOsJ3og
]